import { searchStore } from '@context/searchStore';
import { RadioGroup } from '@headlessui/react';
import { createSearchQuery, defaults } from '@helpers/searchQuery';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Checkbox from './FacetComponents/Checkbox';
import Radio from './FacetComponents/Radio';

const checkboxGroup = (facetData, count, loading, facetFamily, facets) => {
  return facetData.values
    .slice(0, count)
    .map((option, index) => (
      <Checkbox
        option={option}
        key={index}
        loading={loading}
        facetFamily={facetFamily}
        facets={facets}
      />
    ));
};

const radioGroup = (
  facetData,
  count,
  loading,
  facetFamily,
  searchState,
  facets,
  handleClearFilter,
) => {
  const [selected, setSelected] = useState();
  const [showClearFilter, setShowClearFilter] = useState();

  useEffect(() => {
    searchState.facets.single.forEach((singleFacet) => {
      if (singleFacet.name == facetFamily) {
        const index = facetData.values.findIndex((x) => x.label == singleFacet.value);
        if (facetData.values[index]) {
          setSelected(facetData.values[index]);
          setShowClearFilter(true);
        } else {
          setShowClearFilter(false);
        }
      }
    });
  }, [facets]);

  return (
    <>
      {showClearFilter && (
        <button
          className="pr-2 font-secondary font-light text-accent underline"
          onClick={() => {
            handleClearFilter();
            setShowClearFilter(false);
          }}
        >
          herstel filter
        </button>
      )}

      <RadioGroup value={selected} onChange={setSelected}>
        {facetData.values.slice(0, count).map((option, index) => (
          <Radio option={option} key={index} loading={loading} facetFamily={facetFamily} />
        ))}
      </RadioGroup>
    </>
  );
};

const showHideItems = (facetData, open, handleOnClick) => {
  return (
    facetData.values.length > 6 && (
      <button
        className="pb-6 pt-4 font-secondary font-light leading-5 text-accent underline"
        onClick={handleOnClick}
      >
        {open ? 'toon minder' : 'toon alles'}
      </button>
    )
  );
};

export default function FacetFilter(props) {
  const { facetData, loading, facetFamily, facets } = props;
  const [count, setCount] = useState(6);
  const [open, setOpen] = useState(false);
  const searchState = searchStore((state) => state.searchState);
  const router = useRouter();

  function handleClearFilter() {
    const facet = searchState.facets.single.find((facet) => facet.name === facetFamily);

    const setWildcard = Object.keys(defaults).includes(facet.name);

    const query = createSearchQuery(facet.name, setWildcard ? '*' : null, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  }

  function handleOnClick() {
    if (open) {
      setCount(6);
      setOpen(false);
    } else {
      setCount(facetData.values.length);
      setOpen(true);
    }
  }

  switch (facetData.type) {
    case 'checkbox':
      return (
        <div className="flex flex-col gap-3">
          {checkboxGroup(facetData, count, loading, facetFamily, facets)}
          {loading ? (
            <div className="w-2/5">
              <Skeleton />
            </div>
          ) : (
            <div>{showHideItems(facetData, open, handleOnClick)}</div>
          )}
        </div>
      );
    case 'radio':
      return (
        <div>
          {radioGroup(
            facetData,
            count,
            loading,
            facetFamily,
            searchState,
            facets,
            handleClearFilter,
          )}
          {loading ? (
            <div className="w-2/5">
              <Skeleton />
            </div>
          ) : (
            showHideItems(facetData, open, handleOnClick)
          )}
        </div>
      );
  }
}
