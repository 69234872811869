import { facetConfig, facetDefaults } from '@config/facetConfig';
import { searchStore } from '@context/searchStore';
import Container from '@elements/Container';
import Seo from '@elements/Seo';
import buildSearchBody from '@helpers/buildSearchBody';
import { getDomain } from '@helpers/domains';
import { setDefaultQueryValues } from '@helpers/searchQuery';
import Layout from '@layout/Layout';
import { performServerSearch } from '@providers/search';
import SearchLayout from '@search/SearchLayout';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export default function SearchPage(props) {
  const [loading, setLoading] = useState(true);
  const { aggregations, hits, resultCount, status } = props;

  if (!status) {
    return (
      <Layout>
        <div className="flex h-[50vh] items-center justify-center bg-accent-3">
          <div className="max-w-7xl p-4">
            <h1 className="pt-4 text-center font-primary text-2xl font-light leading-7 text-primary-dark antialiased md:text-[33px] md:leading-[44px] lg:text-[48px] lg:font-light lg:leading-[57px]">
              Zoeken is momenteel niet beschikbaar
            </h1>
          </div>
        </div>
      </Layout>
    );
  }

  const categories = [
    { name: 'Alles', disabled: false, hits: resultCount },
    { name: 'Producten', disabled: true, hits: 0 },
    { name: 'Topics', disabled: true, hits: 0 },
    { name: 'Kennisvragen', disabled: true, hits: 0 },
  ];
  // Gets the current search store
  const [searchState, setSearchState] = searchStore((state) => [
    state.searchState,
    state.setSearchState,
  ]);

  const domain = getDomain();

  function useDeepEffect(fn, deps) {
    const isFirst = useRef(true);
    const prevDeps = useRef(deps);

    useEffect(() => {
      const isFirstEffect = isFirst.current;
      const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

      isFirst.current = false;
      prevDeps.current = deps;

      if (isFirstEffect || !isSame) {
        return fn();
      }
    }, deps);
  }

  useDeepEffect(() => {
    updateSearchState(props.query);
    if (hits) {
      setLoading(false);
    }
  }, [props.query]);

  function updateSearchState(query) {
    // Single facet elements
    let currentSingleFacetState = [];
    for (const [key, value] of Object.entries(query)) {
      if (facetConfig.single.includes(key)) {
        currentSingleFacetState.push({
          name: key,
          value: value,
        });
      }
    }
    searchState.facets.single = currentSingleFacetState;

    if (currentSingleFacetState.length < 1) {
      searchState.facets.single = facetDefaults.single;
    }

    // Multi facet elements
    let currentMultiFacetState = [];
    for (const [key, value] of Object.entries(query)) {
      if (facetConfig.multi.includes(key)) {
        currentMultiFacetState.push({
          name: key,
          value: value.split('+'),
        });
      }
    }
    searchState.facets.multi = currentMultiFacetState;

    if (currentMultiFacetState.length < 1) {
      searchState.facets.multi = facetDefaults.multi;
    }

    // Root elements
    searchState.view = query.view;
    searchState.page = query.page;
    searchState.sort = query.sort;
    searchState.query = query.query;
    searchState.bucket = query.bucket;
    setSearchState({ ...searchState });
  }

  return (
    <Layout>
      <Seo
        title="Zoekresultaten"
        description="Vind hier je zoekresultaten."
        url={`https://${domain}/search`}
      />
      <div className="bg-accent-3">
        <div className="mx-auto max-w-7xl px-4 py-16">
          <h1 className="pt-10 font-primary text-2xl font-light leading-7 text-primary-dark antialiased md:text-[33px] md:leading-[44px] lg:text-[48px] lg:font-light lg:leading-[57px]">
            Alle kennis van BouwZo
          </h1>
        </div>
      </div>
      <Container>
        <SearchLayout
          categories={categories}
          loading={loading}
          aggregations={aggregations}
          hits={hits}
          resultCount={resultCount}
        />
      </Container>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const { req, res, query } = context;
  setDefaultQueryValues(query);

  const body = buildSearchBody(query);
  const response = await performServerSearch(req, res, body);

  if (response?.status == 200) {
    const hits = response.data.hits.hits;
    const aggregations = response.data.aggregations;
    const resultCount = response.data.hits.total.value;

    return {
      props: {
        query: query,
        aggregations: aggregations,
        hits: hits,
        resultCount: resultCount,
        status: true,
      },
    };
  } else {
    return {
      props: {
        status: false,
      },
    };
  }
}
