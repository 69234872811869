import { searchStore } from '@context/searchStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { createSearchQuery } from '@helpers/searchQuery';
import SearchResults from '@search/SearchResults';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Facets from './Facets';
import SortDropdown from './SortDropdown';

const SearchLayout = (props) => {
  const { categories, loading, aggregations, hits, resultCount } = props;
  const router = useRouter();

  // Gets the current search store
  const searchState = searchStore((state) => state.searchState);

  const [open, setOpen] = useState(false);
  const onClickFilter = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  function setSearchView(view) {
    const query = createSearchQuery('view', view, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  }

  function handleViewClick() {
    if (searchState.view == 'tile') {
      setSearchView('list');
    } else {
      setSearchView('tile');
    }
  }

  return (
    <div className="relative">
      {/* medium + */}
      <Tab.Group>
        <div className="hidden w-full items-end justify-center md:flex">
          <Tab.List className="flex w-full p-1">
            {categories.map((category) => (
              <Tab
                disabled={category.disabled}
                key={category.name}
                className={({ selected }) =>
                  classNames(
                    'w-1/4 py-2.5 font-secondary text-sm font-bold leading-5 tracking-wide',
                    !!selected && 'border-b-2 border-accent text-accent outline-none',
                    category.disabled && 'opacity-50',
                  )
                }
              >
                {category.name} ({category.hits})
              </Tab>
            ))}
          </Tab.List>
        </div>
        {/* small */}
        <div className="flex snap-x snap-mandatory overflow-y-auto md:hidden">
          <Tab.List className="flex gap-4 px-3">
            {categories.map((category) => (
              <Tab
                disabled={category.disabled}
                key={category.name}
                className={({ selected }) =>
                  classNames(
                    'w-fit whitespace-nowrap py-2.5 pt-8 text-sm font-medium leading-5',
                    !!selected && ' border-b-2 border-accent text-accent outline-none',
                    category.disabled && 'opacity-50',
                  )
                }
              >
                {category.name} ({category.hits})
              </Tab>
            ))}
          </Tab.List>
        </div>
      </Tab.Group>
      <div className="flex flex-col px-3 pt-8 md:hidden">
        <Button
          buttonType="primary"
          label="Filter"
          type="button"
          icon="chevron-right"
          iconPosition="left"
          overrideClass="!max-w-full"
          onClick={onClickFilter}
        />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-96">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4"></div>
                    </Transition.Child>
                    <div className="h-full overflow-y-auto bg-white p-8">
                      <div className="space-y-6 pb-16 ">
                        <div className="flex justify-end">
                          <button type="button" onClick={() => setOpen(false)}>
                            <span className="sr-only">Close panel</span>
                            <Icon iconName="x" color="text-accent" />
                          </button>
                        </div>
                        <Facets loading={loading} aggregations={aggregations} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex gap-4 bg-functional-contrast pt-10 md:px-3">
        {/* <div className="bg-4 hidden h-[1444px] w-[224px] bg-red-600 md:block" /> */}
        <div className="hidden w-[288px] md:block">
          <Facets loading={loading} aggregations={aggregations} />
        </div>
        <div className="w-full px-4 md:pl-8">
          <div className="flex w-full justify-between p-2 pb-4">
            <div className="hidden font-secondary text-primary-dark md:block">
              {loading ? (
                <div className="w-28 text-xl">
                  <Skeleton />
                </div>
              ) : (
                <p>{resultCount} resultaten</p>
              )}
            </div>
            <div className="flex gap-3">
              <p className="pt-2 font-secondary font-bold"> Sorteer op:</p>
              <SortDropdown />
              <button onClick={handleViewClick} className="size-11">
                <Icon
                  iconName={searchState.view == 'tile' ? 'list' : 'grid'}
                  size="2xl"
                  color="text-accent"
                />
              </button>
            </div>
          </div>
          <SearchResults
            view={searchState.view}
            loading={loading}
            hits={hits}
            resultCount={resultCount}
          />
        </div>
      </div>
    </div>
  );
};

SearchLayout.propTypes = {
  hits: PropTypes.array,
  aggregations: PropTypes.object,
  resultCount: PropTypes.number,
  categories: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

// Default prop types if certain values are omitted
SearchLayout.defaultProps = {
  hits: [],
  aggregations: null,
  resultCount: 0,
  categories: [],
  loading: true,
};

export default SearchLayout;
