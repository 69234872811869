import Icon from '@elements/Icon';
import { Disclosure } from '@headlessui/react';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';
import { getFacetsFromAggregations } from '../utils/getFacetsFromAggregations';
import FacetFilter from './FacetFilter';
import LastSearchTerm from './LastSearchTerm';

export default function Facets(props) {
  const { loading, aggregations } = props;
  const facets = getFacets(aggregations);
  const router = useRouter();

  function handleClearFilters() {
    const url = {
      pathname: router.pathname,
    };
    router.push(url, undefined, { scroll: false });
  }

  function renderFacetDisclosure(option, index) {
    return (
      <Disclosure defaultOpen key={index}>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between py-2 text-left font-secondary font-black tracking-wide">
              {loading ? (
                <div className="w-48 text-2xl">
                  <Skeleton />
                </div>
              ) : (
                option.displayName
              )}
              {open ? (
                <Icon iconName="chevron-up" color="#000000" />
              ) : (
                <Icon iconName="chevron-down" color="#000000" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div key={index}>
                <FacetFilter
                  facetData={option}
                  loading={loading}
                  facetFamily={option.category}
                  facets={facets}
                />
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  }

  function renderFacetDisclosures() {
    return facets.map((option, index) => renderFacetDisclosure(option, index));
  }

  return (
    <div>
      <div className="flex justify-between pb-4">
        <div className="font-secondary font-black">Filters</div>
        <button
          className="pr-2 font-secondary font-light text-accent underline"
          onClick={handleClearFilters}
        >
          herstel alles
        </button>
      </div>
      {!loading && <LastSearchTerm />}
      <div className="flex flex-col gap-2">{renderFacetDisclosures()}</div>
    </div>
  );
}

function getFacets(aggregations) {
  const facets = getFacetsFromAggregations(aggregations);
  return facets;
}
